@import '../../../../resources/css/sass/variables';

.carousel {
  position: relative;
  width: 58.5vw;
  height: 33vw;
  //overflow: hidden;

  &_slide {
    position: absolute;
    //top: 0;
    bottom: -33px;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 1;
    background-color: white;
    display: flex;
    align-items: center;

    img {
      max-width: 100%;
    }

  }

  &_history {
    position: absolute;
    z-index: 2;
    bottom: -22px;
    width: 58%;
    margin-left: 14px;
    //margin-left: -3%;
    margin-bottom: -6px;
    //transform: translateX(-50%);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      width: 108%;
      //height: 3px;

      li {
        /*width: 15%;*/
        box-sizing: border-box;
        height: inherit;
        position: relative;
        &:not(:last-of-type) {
          margin-right: 5%;
        }
        /*button {
          position: absolute;
          top: 0;
          border: 0;
          //width: 2px;
          height: 0.87rem;
          width: .87rem;
          border-radius: 50%;
          background: #d7d7d7;
          cursor: pointer;
          transition: background-color 300ms ease;
          &.active {
            background-color: #4483FD;
          }
        }*/
      }
    }
  }
  .container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .circle {
          width: .81rem;
          background-color: #d7d7d7;
          border-radius: 50%;
          cursor: pointer;
        }
        .active {
          background-color: #4483FD !important;
          width: .81rem;
          border-radius: 50%;
          cursor: pointer;
        }

        .circle::before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

  &_control {
    position: absolute;
    z-index: 2;
    top: 0;
    height: 30px;
    width: 48%;
    //height: 100%;
    display: block;
    //background-color: rgba(0,0,0,0.5);
    color: white;
    border-radius: 2px;
    border-color: #4483FD;
    border: 1;
    background-color: #4483FD;
    padding: 0 5px;
    overflow: hidden;
    transition: background-color 300ms ease;
    cursor: pointer;

    span {
      //display: inline-block;
      //width: 2vw;
      font-weight: 700;
      padding-top: 5px;
      /*font-size: 1.1em;*/
      height: 2vw;
      position: relative;
      box-sizing: border-box;
      transition: opacity 300ms ease;
    }

    &:hover {
      background-color: #79a4f7;
      transform: scale 1.02;

      span {
        opacity: 0.95;
      }
    }
    &:active {
      transform: scale(.99);      
    }

    &__prev {
      color: #4483FD;
      border-radius: 2px;
      border-color: white;
      border: 1;
      background-color: white;
      
      left: 0;
        span {
          transition: opacity 300ms ease;
        }
    }

    &__next {
      right: 0;
       span {
         transition: opacity 300ms ease;
       }
    }

    &__prev_fix {
      color: #4483FD;
      border-radius: 2px;
      border-color: white;
      border: 1;
      background-color: white;
      
      left: 52%;
        span {
          transition: opacity 300ms ease;
        }
    }
  }

}

// animation for slide
.leave {
  transform: translateX(0);
}

.enter-active.enter-next, .enter-active.enter-prev {
  transform: translateX(0);
  transition: transform 500ms linear;
}

.enter-next {
  transform: translateX(100%);
}

.enter-prev {
  transform: translateX(-100%);
  transition: transform 500ms linear;
}

.leave-active-next {
  transform: translateX(-100%);
  transition: transform 500ms linear;;
}

.leave.leave-active-prev {
  transform: translateX(100%);
  transition: transform 500ms linear;
}

.buttonsWrapper{
    right: 14px;
    bottom: -8px;
    width: 143px;
    position: absolute;
}

.app {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: $bp-small) {
  /*.history ul {
    width: 126%;
  }
  .history {
    width: 25%;
  }*/
  .control{
    font-size: 0.6em;
    height: 10px;
  }
  .carousel{
    width: 98vw;
    height: 58vw;
  }
}

@media only screen and (max-width: 700px) {
  .buttonsWrapper{
      width: 30%;
  }
}
@media only screen and (max-width: 770px) {
  .buttonsWrapper{
      width: 25%;
  }
}
@media only screen and (max-width: 730px) {
  .carousel{
    &_history {
      width: 25% !important;
    }
  }
}
