//import styled from "styled-components";
@import '../../../resources/css/sass/variables';

.modalFade {
  position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  //right: 0;
  //bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.4s;
  z-index: 2000;
  //width: 800px;
  margin: 40px auto;
}
.fadeIn {
    opacity: 1;
    transition: opacity linear 0.4s;
  }
.fadeOut {
    opacity: 0;
    transition: opacity linear 0.5s;
}
.background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
}

  .boxDialog {
    z-index: 1050;
    //margin-top: 5%;
    //width: 70%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    //background-color: #fefefe;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }
    .boxContent {
      padding-bottom: 24px; // padding: 24px;
      width: 100%;
    }
    .boxHeader {
      height: 75px; //100px
      padding: 8px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //border-bottom: 1px solid #c7c7c7;
    }
      .box-title {
        font-size: 3vw;
        font-weight: 400;
        margin: 0 0 0 0;
      }
      .xClose {
        width: 23px;
        border-color: #4483FD;
        border: none;
        color: #4483FD;
        text-align: center;
        padding-bottom: 2px;
        padding-left: 5px;
        border-radius: 19px;
        line-height: 18px;
        /*font-size: 18px;*/
        font-size: 1.5em;
        background-color: white;
        margin-right: -18px;
        margin-top: -40px;
        font-weight: 400;
        opacity: 0.8;
        cursor: pointer;
        &:hover {
          opacity: 0.55;
        }
      }
      .close {
        font-size: 1.1vw;
        line-height: 20px;
        //margin-right: -69px;
        margin-top: 0px;
        background: none;
        border: 0;
        font-weight: 400;
        text-shadow: none;
        color: #4483FD;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }

    .boxBody {
      font-size: 2vw;
      padding: 0px;
      width: auto;
      //height: auto;
      height: 30%;
    }
    .boxFooter {
      height: 30px;
      padding: 0px 24px 15px;
      display: flex;
      align-items: center;
      justify-content: center; //flex-end
      //border-top: 1px solid #c7c7c7;
    }
    .caption{
      width: 52%; //45
      text-align: left;
      padding-bottom: 8px;
      position: absolute;
      line-height: 1.6;
      font-size: 1em;
      /*font-size: 1.28vw;*/
      font-family: Open Sans;
      color: #4483FD;
    }

    @media only screen and (max-width: $bp-medium) {
       .caption{
         //font-size: 1vw;
         width: 53%;
         font-size: 0.9em;
       }
    }
    @media only screen and (max-width: 887px) {
       .caption{
         width: 49%;
         font-size: 0.8em;
       }
    }

    @media only screen and (max-width: $bp-small) {
      .boxDialog {
         margin-top: 30%;
       }
       .caption{
         //font-size: 2.0vw;
         font-size: 2.7vw;
         width: 76%;
       }
       /*.xClose {
         padding-left: 5px;
       }*/
    }
