@import '../../../resources/css/sass/mixins';

.modalCard {
  position: absolute;
  border-radius: 6px;
  background-color: white;
  font-family: "Open Sans", sans-serif;
}

.modalHeader {
  background-image: url(../../../resources/img/sign-in-header-red.jpg);
  background-size: cover;
  height: 125px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .close{
    align-self: flex-start;
    position: absolute;
    right: 5px;
  }
}

.loginContent {
  padding: 20px;
  color: grey;
  text-align: center;
}

.infoBar {
  height: 2em;
  display: flex;
  flex-direction: column;
}

.loginOptions {
  margin: 0 auto 2em auto;
  .btnSocial, .btnSocialIcon {
    text-overflow: inherit;
  }

  .btnSocial {
    box-sizing: content-box;
    color: white;
    display: block;
    width: 240px;
    margin: 0 auto 5px;
    padding: 0.2em 0.5em 0.2em 1.5em;
    border-radius: 6px;
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1em;

    .socialLogo {
      font-size: 1.8em;
      margin-right: 20px;
      position: absolute;
      left: 25px;
    }
  }

  .facebook {
    background-color: #3b5998 !important;
    @include hover-supported() {
      &:hover {
        background-color: #334774 !important;
      }
    }
  }

  .linkedin {
    background-color: #007bb6 !important;
    @include hover-supported() {
      &:hover {
        background-color: #025179 !important;
      }
    }

  }
  &:last-child {
    margin-bottom: 0;
  }
}

.modalFooter {
  font-size: 0.9em;
  font-weight: bold;
}
