@import '../../../../../resources/css/sass/variables';

.wrapper{
  margin-left: auto;
  margin-right: auto;
  margin-top: -130px;
}
.wrapperSecondSlide{
  margin-left: auto;
  margin-right: auto;
  margin-top: -130px;
}
.title{
  font-family: Open Sans;
  padding-bottom: 1%;
  font-style: bold;
  font-weight: 900;
  font-size: 3.5vw;
  //line-height: 33px;
  color: #4483FD;
  text-align: left;
}
.subTitle{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 750;
  font-size: 2.2vw;
  //line-height: 33px;
  color: #4483FD;
  text-align: left;
}
.slogan{
  margin-top: 22px;
  text-align: left;
  font-size: 1.3vw;
  font-style: italic;
  font-family: Open Sans;
  color: #4483FD;
}

.paragraph{
  line-height: 200%;
  width: 85%;
  font-family: Open Sans;
  color: #4483FD;
  padding-bottom: 13px;
  text-align: left;
  font-size: 1.3vw;
  margin-left: auto;
  margin-right: auto;
}
.paragraphTitle {
 font-weight: bold;
 font-size: 1.7vw;
}

@media only screen and (max-width: $bp-small) {
  .paragraph {
    font-size: 2.5vw;//2.1
  }
  .title{
    font-size: 5vw;
  }
  .subTitle{
    font-size: 3vw;
  }
  .slogan{
    font-size: 2.5vw;
    width: 90%;
  }
  .wrapper{
    margin-left: 60px;
  }
  .wrapperSecondSlide{
    margin-left: 5px;
  }
}
