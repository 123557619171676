@import '../../../../resources/css/sass/variables';

.wrapper {
  //position: relative;
  /* Player ratio: 100 / (1280 / 720) */
}
.playerWrapper {
  //position: relative;
  //padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  top: 0px !important;


}

/*class="video-stream html5-main-video"*/
/*.video-stream{
  top: 0px !important;
  height: 130% !important;
}
.html5-video-container{
  top: 0px !important;
  height: 130% !important;
}
.html5-main-video{
  top: 0px !important;
  height: 130% !important;
}*/

.reactPlayer {
  position: absolute;
  top: -42px;
  left: 0;
}

@media only screen and (max-width: $bp-small) {
  .caption{
    width: 85%;
    font-size: 2vw;

    //min-inline-size: fit-content;
  }
}
